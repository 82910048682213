import jwt_decode from 'jwt-decode'
import { TokenPayload } from '../type/TokenPayload'
export const readToken = (token: string): TokenPayload => {
  if (!token) {
    return { isCredentialValid: false }
  }
  const decode = jwt_decode<any>(token)
  const { user_id, user_type, exp, role_id, company_id } = decode
  const isCredentialValid = Math.floor(Date.now() / 1000) <= exp
  return {
    user_id,
    user_type,
    exp,
    role_id,
    isCredentialValid,
    company_id
  }
}
