export function createSimpleEmitter<T = void>() {
  const listCallback: ((args: T) => Promise<void>)[] = []
  return {
    emitAll: function (args: T) {
      return Promise.all(listCallback.map((func) => func(args)))
    },
    regiterCallback: function (callBackFuction: (args: T) => Promise<void>) {
      listCallback.push(callBackFuction)
    },
    unregisterCallBack: function (callBackFuction: (args: T) => Promise<void>) {
      const index = listCallback.indexOf(callBackFuction)
      listCallback.splice(index, 1)
    }
  }
}
