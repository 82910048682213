import { AxiosResponse } from 'axios'
import { CompanyDetailType, FastJsonObject } from '../type/Common'
import {
  API_URL_GET_PROFILE_COMPANY,
  API_URL_GET_PROFILE_CONSULTANTS,
  API_URL_GET_PROFILE_CANDIDATE,
  API_URL_GET_PROFILE_ADMIN
} from '../utilities/APIConstant'
import client, { X_AUTH_TOKEN } from '../utilities/Client'
import { getCookie } from '../utilities/Cookies'
import { readToken } from '../utilities/JWTToken'
import { createGlobalStage } from './share/GlobalStateHooks'
import { EnumUserType } from '../Enum/UserTypeEnum'

type CurrentCompanyType = {
  company?: CompanyDetailType
}

const getAuthenTicateAPI = (userType) =>
  ({
    [EnumUserType.CANDIDATE]: API_URL_GET_PROFILE_CANDIDATE,
    [EnumUserType.CONSULTANT]: API_URL_GET_PROFILE_CONSULTANTS,
    [EnumUserType.RECRUITER]: API_URL_GET_PROFILE_COMPANY,
    [EnumUserType.ADMIN]: API_URL_GET_PROFILE_ADMIN
  }[userType])

const [useCurrentCompanyStorage] = createGlobalStage<CurrentCompanyType>(() => {
  if (getCookie(X_AUTH_TOKEN)) {
    const jwt = readToken(getCookie(X_AUTH_TOKEN))
    if (jwt.user_type === EnumUserType.RECRUITER || jwt.user_type === EnumUserType.CONSULTANT) {
      return client.get(getAuthenTicateAPI(jwt.user_type), {}).then((response: AxiosResponse<FastJsonObject<CompanyDetailType>>) => {
        const profile = response.data.data.attributes
        return { company: profile }
      })
    }
  }
  return Promise.resolve(undefined)
})

// createUseLocalStorageStage<CurrentCompanyType>('current-company', )

export const useCurrentCompany = () => {
  const [currentCompany] = useCurrentCompanyStorage()
  return [currentCompany]
}
export const useLogout = () => {
  const [, setCurrentCompany] = useCurrentCompanyStorage()
  return () => {
    client.logout()
    //@ts-ignore
    if (typeof window?.Gleap === 'function') {
      //@ts-ignore
      window.Gleap.clearIdentity()
    }
    setCurrentCompany(undefined)
    return Promise.resolve()
  }
}

export const useFetchAndStoreCompany = () => {
  const [, setCurrentUser] = useCurrentCompanyStorage()
  const fetchAndStoreCompany = () => {
    const jwt = readToken(getCookie(X_AUTH_TOKEN))
    client.get(getAuthenTicateAPI(jwt.user_type), {}).then((response: AxiosResponse<FastJsonObject<CompanyDetailType>>) => {
      const profile = response.data.data.attributes
      setCurrentUser({ company: profile })
      return profile
    })
  }
  return fetchAndStoreCompany
}
