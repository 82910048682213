import { AxiosResponse } from 'axios'
import { EnumUserType } from '../Enum/UserTypeEnum'
import { ProfileType } from '../type/CandidateProfile'
import { FastJsonObject } from '../type/Common'
import {
  API_URL_ADMIN_FETCH_ME,
  API_URL_CANDIDATE_FETCH_ME,
  API_URL_COMPANY_FETCH_ME,
  API_URL_CONSULTANT_FETCH_ME
} from '../utilities/APIConstant'
import client, { X_AUTH_TOKEN } from '../utilities/Client'
import {
  COOKIES_NAME_CANDIDATE_ONBOADING,
  deleteCookie,
  getCookie,
  setCookie,
  COOKIES_NAME_COMPANY_ID,
  COOKIES_NAME_LANGUAGE
} from '../utilities/Cookies'
import { NUMBER_LANGUAGE_MAP } from '../utilities/Enum'
import { readToken } from '../utilities/JWTToken'
import { useFetchAndStoreCompany } from './CurrentCompanyHooks'
import { createGlobalStage } from './share/GlobalStateHooks'
import { useNotificationModalState } from '../../../modules/notifications/hooks/useNotification'
export type CurrentUserType = {
  profile?: ProfileType
}

export const useCurrentUser = () => {
  const [currentUser] = useCurrentUserStorage()
  return [currentUser]
}

const [useCurrentUserStorage, , , refetchUser] = createGlobalStage<CurrentUserType>(
  // 'current-user',
  () => {
    if (getCookie(X_AUTH_TOKEN)) {
      const jwt = readToken(getCookie(X_AUTH_TOKEN))
      return client.get(getAuthenTicateAPI(jwt.user_type), {}).then((response: AxiosResponse<FastJsonObject<ProfileType>>) => {
        return { profile: response?.data?.data?.attributes }
      })
    }
    return Promise.resolve(undefined)
  }
)
export const refetchCurrentUser = refetchUser
export const useLogout = () => {
  const [, setCurrentuser] = useCurrentUserStorage()
  return () => {
    client.logout()
    //@ts-ignore
    if (typeof window?.Gleap === 'function') {
      //@ts-ignore
      window.Gleap.clearIdentity()
    }
    setCurrentuser(undefined)
    deleteCookie(COOKIES_NAME_COMPANY_ID)
    deleteCookie('_vercel_no_cache')
    return Promise.resolve()
  }
}
const getAuthenTicateAPI = (userType) =>
  ({
    0: API_URL_CANDIDATE_FETCH_ME,
    [EnumUserType.CANDIDATE]: API_URL_CANDIDATE_FETCH_ME,
    [EnumUserType.CONSULTANT]: API_URL_CONSULTANT_FETCH_ME,
    [EnumUserType.RECRUITER]: API_URL_COMPANY_FETCH_ME,
    [EnumUserType.ADMIN]: API_URL_ADMIN_FETCH_ME
  }[userType])

export const useFetchAndStoreUser = () => {
  const [, setCurrentUser] = useCurrentUserStorage()
  // const { changeLanguage } = useTranslation()
  const fetchAndStoreUser = () => {
    const token = readToken(getCookie(X_AUTH_TOKEN))
    return client.get(getAuthenTicateAPI(token.user_type), {}).then((response: AxiosResponse<FastJsonObject<ProfileType>>) => {
      const profile = response.data.data.attributes
      setCurrentUser({ profile: profile })
      // if (!preventChangeLanguage) {
      //   changeLanguage(fromNumberToCode(profile.language))
      // }
      return profile
    })
  }
  return fetchAndStoreUser
}
export const useStoreTokenAndFetchUser = (): ((token: string) => Promise<any>) => {
  const [, setOpenNotificationModal] = useNotificationModalState()
  const fetchAndStoreUser = useFetchAndStoreUser()
  const fetchAndStoreCompany = useFetchAndStoreCompany()
  return (token) => {
    setOpenNotificationModal(true)
    client.setToken(token)
    const jwt = readToken(token)
    if (jwt.user_type === EnumUserType.RECRUITER) {
      setCookie(COOKIES_NAME_COMPANY_ID, jwt.company_id.toString())
    }
    if (jwt.user_type === EnumUserType.RECRUITER || jwt.user_type === EnumUserType.CONSULTANT) {
      return Promise.all([
        fetchAndStoreUser().then((profile) => {
          setCookie(COOKIES_NAME_LANGUAGE, NUMBER_LANGUAGE_MAP[profile.language])
          return profile
        }),
        fetchAndStoreCompany()
      ])
    }
    return fetchAndStoreUser().then((profile) => {
      if (!profile.is_on_boarding_success && !profile.skip_onboarding) {
        setCookie(COOKIES_NAME_CANDIDATE_ONBOADING, 'true')
      } else {
        deleteCookie(COOKIES_NAME_CANDIDATE_ONBOADING)
      }
      deleteCookie('_vercel_no_cache')
      setCookie(COOKIES_NAME_LANGUAGE, NUMBER_LANGUAGE_MAP[profile.language])
      return profile
    })
  }
}
