export default {
  data: [
    { id: '27', type: 'location', attributes: { id: 27, state: 'Ho Chi Minh', state_vn: 'Hồ Chí Minh' } },
    { id: '22', type: 'location', attributes: { id: 22, state: 'Ha Noi', state_vn: 'Hà Nội' } },
    { id: '14', type: 'location', attributes: { id: 14, state: 'Da Nang', state_vn: 'Đà Nẵng' } },
    { id: '1', type: 'location', attributes: { id: 1, state: 'An Giang', state_vn: 'An Giang' } },
    { id: '59', type: 'location', attributes: { id: 59, state: 'Bangkok - Thailand', state_vn: 'Bangkok - Thailand' } },
    { id: '2', type: 'location', attributes: { id: 2, state: 'Ba Ria - Vung Tau', state_vn: 'Bà Rịa - Vũng Tàu' } },
    { id: '8', type: 'location', attributes: { id: 8, state: 'Binh Duong', state_vn: 'Bình Dương' } },
    { id: '9', type: 'location', attributes: { id: 9, state: 'Binh Phuoc', state_vn: 'Bình Phước' } },
    { id: '10', type: 'location', attributes: { id: 10, state: 'Binh Thuan', state_vn: 'Bình Thuận' } },
    { id: '7', type: 'location', attributes: { id: 7, state: 'Binh Dinh', state_vn: 'Bình Định' } },
    { id: '4', type: 'location', attributes: { id: 4, state: 'Bac Lieu', state_vn: 'Bạc Liêu' } },
    { id: '3', type: 'location', attributes: { id: 3, state: 'Bac Giang', state_vn: 'Bắc Giang' } },
    { id: '5', type: 'location', attributes: { id: 5, state: 'Bac Ninh', state_vn: 'Bắc Ninh' } },
    { id: '6', type: 'location', attributes: { id: 6, state: 'Ben Tre', state_vn: 'Bến Tre' } },
    { id: '13', type: 'location', attributes: { id: 13, state: 'Cao Bang', state_vn: 'Cao Bằng' } },
    { id: '11', type: 'location', attributes: { id: 11, state: 'Ca Mau', state_vn: 'Cà Mau' } },
    { id: '12', type: 'location', attributes: { id: 12, state: 'Can Tho', state_vn: 'Cần Thơ' } },
    { id: '19', type: 'location', attributes: { id: 19, state: 'Gia Lai', state_vn: 'Gia Lai' } },
    { id: '28', type: 'location', attributes: { id: 28, state: 'Hoa Binh', state_vn: 'Hoà Bình' } },
    { id: '20', type: 'location', attributes: { id: 20, state: 'Ha Giang', state_vn: 'Hà Giang' } },
    { id: '21', type: 'location', attributes: { id: 21, state: 'Ha Nam', state_vn: 'Hà Nam' } },
    { id: '23', type: 'location', attributes: { id: 23, state: 'Ha Tinh', state_vn: 'Hà Tĩnh' } },
    { id: '29', type: 'location', attributes: { id: 29, state: 'Hung Yen', state_vn: 'Hưng Yên' } },
    { id: '24', type: 'location', attributes: { id: 24, state: 'Hai Duong', state_vn: 'Hải Dương' } },
    { id: '25', type: 'location', attributes: { id: 25, state: 'Hai Phong', state_vn: 'Hải Phòng' } },
    { id: '26', type: 'location', attributes: { id: 26, state: 'Hau Giang', state_vn: 'Hậu Giang' } },
    { id: '39', type: 'location', attributes: { id: 39, state: 'Others', state_vn: 'Khác' } },
    { id: '30', type: 'location', attributes: { id: 30, state: 'Kien Giang', state_vn: 'Kiên Giang' } },
    { id: '31', type: 'location', attributes: { id: 31, state: 'Kon Tum', state_vn: 'Kon Tum' } },
    { id: '32', type: 'location', attributes: { id: 32, state: 'Lai Chau', state_vn: 'Lai Châu' } },
    { id: '35', type: 'location', attributes: { id: 35, state: 'Long An', state_vn: 'Long An' } },
    { id: '34', type: 'location', attributes: { id: 34, state: 'Lao Cai', state_vn: 'Lào Cai' } },
    { id: '33', type: 'location', attributes: { id: 33, state: 'Lam Dong', state_vn: 'Lâm Đồng' } },
    { id: '36', type: 'location', attributes: { id: 36, state: 'Nghe An', state_vn: 'Nghệ An' } },
    { id: '37', type: 'location', attributes: { id: 37, state: 'Ninh Binh', state_vn: 'Ninh Bình' } },
    { id: '38', type: 'location', attributes: { id: 38, state: 'Ninh Thuan', state_vn: 'Ninh Thuận' } },
    { id: '40', type: 'location', attributes: { id: 40, state: 'Overseas', state_vn: 'Nước Ngoài' } },
    { id: '41', type: 'location', attributes: { id: 41, state: 'Phu Tho', state_vn: 'Phú Thọ' } },
    { id: '42', type: 'location', attributes: { id: 42, state: 'Phu Yen', state_vn: 'Phú Yên' } },
    { id: '43', type: 'location', attributes: { id: 43, state: 'Quang Binh', state_vn: 'Quảng Bình' } },
    { id: '44', type: 'location', attributes: { id: 44, state: 'Quảng Nam', state_vn: 'Quảng Nam' } },
    { id: '45', type: 'location', attributes: { id: 45, state: 'Quang Ngai', state_vn: 'Quảng Ngãi' } },
    { id: '46', type: 'location', attributes: { id: 46, state: 'Quang Tri', state_vn: 'Quảng Trị' } },
    { id: '60', type: 'location', attributes: { id: 60, state: 'Singapore', state_vn: 'Singapore' } },
    { id: '47', type: 'location', attributes: { id: 47, state: 'Soc Trang', state_vn: 'Sóc Trăng' } },
    { id: '48', type: 'location', attributes: { id: 48, state: 'Son La', state_vn: 'Sơn La' } },
    { id: '51', type: 'location', attributes: { id: 51, state: 'Thanh Hoa', state_vn: 'Thanh Hoá' } },
    { id: '50', type: 'location', attributes: { id: 50, state: 'Thai Binh', state_vn: 'Thái Bình' } },
    { id: '52', type: 'location', attributes: { id: 52, state: 'Thua Thien Hue', state_vn: 'Thừa Thiên Huế' } },
    { id: '53', type: 'location', attributes: { id: 53, state: 'Tien Giang', state_vn: 'Tiền Giang' } },
    { id: '61', type: 'location', attributes: { id: 61, state: 'Tokyo', state_vn: 'Tokyo' } },
    { id: '54', type: 'location', attributes: { id: 54, state: 'Tra Vinh', state_vn: 'Trà Vinh' } },
    { id: '55', type: 'location', attributes: { id: 55, state: 'Tuyen Quang', state_vn: 'Tuyên Quang' } },
    { id: '49', type: 'location', attributes: { id: 49, state: 'Tay Ninh', state_vn: 'Tây Ninh' } },
    { id: '56', type: 'location', attributes: { id: 56, state: 'Vinh Long', state_vn: 'Vĩnh Long' } },
    { id: '57', type: 'location', attributes: { id: 57, state: 'Vinh Phuc', state_vn: 'Vĩnh Phúc' } },
    { id: '58', type: 'location', attributes: { id: 58, state: 'Yen Bai', state_vn: 'Yên Bái' } },
    { id: '15', type: 'location', attributes: { id: 15, state: 'Dac Nong', state_vn: 'Đắc Nông' } },
    { id: '16', type: 'location', attributes: { id: 16, state: 'Dak Lak', state_vn: 'Đắk Lắk' } },
    { id: '17', type: 'location', attributes: { id: 17, state: 'Dong Nai', state_vn: 'Đồng Nai' } },
    { id: '18', type: 'location', attributes: { id: 18, state: 'Dong Thap', state_vn: 'Đồng Tháp' } }
  ]
}
