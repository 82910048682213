import { useEffect, useState } from 'react'
import { createSimpleEmitter } from '../../utillities/Emitter'
import { getIsServer } from '../../utillities/getIsServer'
const { emitAll, regiterCallback, unregisterCallBack } = createSimpleEmitter<any>()
export const useLocalStorageState = <T>(): [T, (srg: T) => void, () => void] => {
  const [state, setState] = useState<T>(
    getIsServer() ? {} : localStorage.getItem('@apply_process') ? JSON.parse(localStorage.getItem('@apply_process')) : undefined
  )
  const setLocalStorageState = (state: T) => {
    localStorage.setItem('@apply_process', state ? JSON.stringify(state) : '')
    emitAll(state)
  }
  const onEvent = (args) => {
    return Promise.resolve(setState(args))
  }
  useEffect(() => {
    regiterCallback(onEvent)
    return () => {
      unregisterCallBack(onEvent)
    }
  }, [])
  return [
    state,
    setLocalStorageState,
    () => {
      localStorage.setItem('@apply_process', '')
      setState(undefined)
    }
  ]
}

export const getFromLocalStorage = (key: string) => {
  if (getIsServer()) {
    return undefined
  }
  const objectString = localStorage.getItem(key)
  return objectString ? JSON.parse(objectString) : undefined
}
export const setToLocalStorage = (key: string, value) => {
  localStorage.setItem(key, value)
}
export function createUseLocalStorageStage<T>(
  modalName: string,
  fetchData?: () => Promise<T>
): [() => [T, (state: T) => void], (args: T) => Promise<void[]>] {
  const { emitAll, regiterCallback, unregisterCallBack } = createSimpleEmitter<any>()
  let fetching = false
  const fetchDataQuee = {
    listResolve: [],
    doQueue() {
      return new Promise<T>(function (resolve) {
        if (!fetching) {
          fetching = true
          fetchData().then(function (rs) {
            fetching = false
            resolve(rs)
          })
        }
      })
    }
  }

  return [
    function useCookiesStorageState(changeDependency = []) {
      const [state, setState] = useState<T>(getFromLocalStorage(`localstorage-hook-${modalName}`))
      const setCookiesState = (state: T) => {
        setToLocalStorage(`localstorage-hook-${modalName}`, state ? JSON.stringify(state) : '')
        emitAll(state)
      }
      const onEvent = (args) => {
        return Promise.resolve(setState(args))
      }
      useEffect(() => {
        if (!state && fetchData) {
          fetchDataQuee.doQueue().then((rs) => {
            setCookiesState(rs)
          })
        }
      }, [])
      useEffect(() => {
        regiterCallback(onEvent)
        return () => {
          unregisterCallBack(onEvent)
        }
      }, changeDependency)
      return [state, setCookiesState]
    },
    emitAll
  ]
}
