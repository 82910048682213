import { useEffect, useState } from 'react'
import { createSimpleEmitter } from '../../utilities/Emitter'

export function createGlobalStage<T>(
  fetchData?: () => Promise<T>
): [() => [T, (state: T) => void], (args: T) => Promise<void[]>, () => T, () => Promise<any>] {
  const { emitAll, regiterCallback, unregisterCallBack } = createSimpleEmitter<any>()
  let fetching = false
  const fetchDataQuee = {
    listResolve: [],
    doQueue() {
      return new Promise<T>(function (resolve) {
        if (!fetching) {
          fetching = true
          fetchData().then(function (rs) {
            fetching = false
            resolve(rs)
          })
        }
      })
    }
  }
  const dataHolder: { data?: T } = {}
  return [
    function useGlobalState(changeDependency = []) {
      const [state, setState] = useState<T>(dataHolder.data)
      useEffect(() => {
        if (!state && fetchData) {
          fetchDataQuee.doQueue().then((rs) => {
            setCookiesState(rs)
          })
        }
      }, [])
      const setCookiesState = (state: T) => {
        dataHolder.data = state
        emitAll(dataHolder.data)
      }
      const onEvent = (args) => {
        return Promise.resolve(setState(args))
      }
      useEffect(() => {
        regiterCallback(onEvent)
        return () => {
          unregisterCallBack(onEvent)
        }
      }, changeDependency)
      return [state, setCookiesState]
    },
    emitAll,
    () => dataHolder.data,
    () => {
      return fetchDataQuee.doQueue().then((rs) => {
        dataHolder.data = rs
        emitAll(dataHolder.data)
      })
    }
  ]
}
