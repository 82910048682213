export const ENUM_JOB_STATUS = {
  draft: 0,
  published: 1,
  closed: 2
}
export const ENUM_JOB_FREEC_STATUS_NUMBER = {
  pending: 0,
  approved: 1,
  rejected: 2,
  expired: 3
}
export const ENUM_COUNT_JOB = {
  published: 'all_published',
  reviewing: 'all_reviewing',
  closed: 'all_closed',
  rejected: 'all_rejected',
  expired: 'all_expired'
}
export const LIST_CLIENT_STATUS = [
  { id: 0, name: 'Pending' },
  { id: 1, name: 'Active' },
  { id: 2, name: 'De-active' },
  { id: 3, name: 'Priority' }
]

export const JOB_STATUS_PUBLISH = 'published'
export const JOB_STATUS_DRAFT = 'draft'
export const JOB_STATUS_REVIEWING = 'reviewing'
export const JOB_STATUS_CLOSED = 'closed'
export const JOB_STATUS_REJECTED = 'rejected'
export const JOB_STATUS_EXPIRED = 'expired'
export const JOB_STATUS_ALL = 'all'

export const JOB_STATUS_MAP = {
  [JOB_STATUS_ALL]: {
    name: 'All',
    name_vn: 'Tất cả',
    freec_status: undefined,
    status: undefined,
    count: 2,
    order_by: 'a',
    empty_title: 'There are no published jobs',
    empty_title_vn: 'Không có việc làm đang tuyển'
  },
  [JOB_STATUS_PUBLISH]: {
    name: 'Published',
    name_vn: 'Đang tuyển',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved,
    status: ENUM_JOB_STATUS.published,
    count: ENUM_COUNT_JOB.published,
    order_by: 'approved_date',
    empty_title: 'There are no published jobs',
    empty_title_vn: 'Không có việc làm đang tuyển'
  },

  [JOB_STATUS_REVIEWING]: {
    name: 'Reviewing',
    name_vn: 'Đang duyệt',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.pending,
    status: ENUM_JOB_STATUS.published,
    count: ENUM_COUNT_JOB.reviewing,
    order_by: 'created_at',
    empty_title: 'There are no reviewing jobs',
    empty_title_vn: 'Không có việc làm đang duyệt'
  },
  [JOB_STATUS_CLOSED]: {
    name: 'Closed',
    name_vn: 'Đã đóng',
    status: ENUM_JOB_STATUS.closed,
    count: ENUM_COUNT_JOB.closed,
    order_by: 'closed_date',
    empty_title: 'There are no closed jobs',
    empty_title_vn: 'Không có việc làm đã đóng'
  },
  [JOB_STATUS_REJECTED]: {
    name: 'Rejected',
    name_vn: 'Từ chối',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.rejected,
    count: ENUM_COUNT_JOB.rejected,
    order_by: 'rejected_date',
    empty_title: 'There are no rejected jobs',
    empty_title_vn: 'Không có việc làm bị từ chối'
  }
}

export const JOB_ORDERS_STATUS_MAP = {
  [JOB_STATUS_PUBLISH]: {
    name: 'Published',
    name_vn: 'Đang tuyển',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.approved,
    status: ENUM_JOB_STATUS.published
  },
  [JOB_STATUS_REVIEWING]: {
    name: 'Reviewing',
    name_vn: 'Đang duyệt',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.pending,
    status: ENUM_JOB_STATUS.published
  },
  [JOB_STATUS_CLOSED]: {
    name: 'Closed',
    name_vn: 'Đã đóng',
    status: ENUM_JOB_STATUS.closed
  },
  [JOB_STATUS_REJECTED]: {
    name: 'Rejected',
    name_vn: 'Từ chối',
    freec_status: ENUM_JOB_FREEC_STATUS_NUMBER.rejected,
    status: ENUM_JOB_STATUS.published
  }
}

export const CONSULTANTS_JOB_TYPES = [
  { id: 0, name: 'Non IT' },
  { id: 1, name: 'IT' }
]

export const REJECT_REASON_CANDIDATE = [
  { id: 0, label: 'not_a_fit', name: 'Not a fit', name_vn: 'Không phù hợp' },
  {
    id: 1,
    label: 'over_qualify',
    name: 'Over Qualify',
    name_vn: 'Vượt yêu cầu'
  },
  { id: 2, label: 'spam', name: 'Spam', name_vn: 'Spam' },
  {
    id: 3,
    label: 'lack_of_skills',
    name: 'Lack of skills',
    name_vn: 'Thiếu kinh nghiệm'
  },
  {
    id: 4,
    label: 'hired_elsewhere',
    name: 'Hired elsewhere',
    name_vn: 'Đã tuyển cty khác'
  },
  {
    id: 5,
    label: 'over_salary_range',
    name: 'Over salary range',
    name_vn: 'Vượt mức lương'
  },
  {
    id: 6,
    label: 'duplication',
    name: 'Duplication',
    name_vn: 'Trùng ứng viên'
  },
  { id: 7, label: 'pending', name: 'Pending', name_vn: 'Tạm ngừng' }
]

export const INTERVIEW_TYPE = {
  0: { name: 'Phone Interview', name_vn: 'Phỏng vấn qua điện thoại' },
  1: { name: 'Online Interview', name_vn: 'Phỏng vấn online' },
  2: { name: 'On-site Interview', name_vn: 'Phỏng vấn ở office' },
  3: { name: 'Final Interview', name_vn: 'Phỏng vấn vòng cuối' }
}

export const PROBATION_DURATION = [
  {
    id: 0,
    name: 'None',
    name_vn: 'None'
  },
  { id: 1, name: '1 month', name_vn: '1 tháng' },
  { id: 2, name: '2 months', name_vn: '2 tháng' },
  { id: 3, name: '3 months', name_vn: '3 tháng' },
  { id: 4, name: '4 months', name_vn: '4 tháng' },
  { id: 5, name: '5 months', name_vn: '5 tháng' },
  { id: 6, name: '6 months', name_vn: '6 tháng' }
]

export const OFFER_STATUS = [
  { id: 0, name: 'Waiting for feedback' },
  { id: 1, name: 'Rejected' },
  { id: 2, name: 'Accepted' }
]

export const COMPANY_TYPE = [
  { id: 0, name: 'All' },
  { id: 1, name: 'Client' },
  { id: 2, name: 'Company' }
]

export const JOB_ORDER_STAGE = [
  { id: 1, name: 'Applied' },
  { id: 2, name: 'Sourced' },
  { id: 3, name: 'Screening' },
  { id: 8, name: 'Send to Client' },
  { id: 5, name: 'Interview' },
  { id: 6, name: 'Offer' },
  { id: 7, name: 'Hired' }
]

export const JOB_POST_STAGE = [
  { id: 1, name: 'Applied', name_vn: 'Ứng tuyển' },
  { id: 3, name: 'Screening', name_vn: 'Lọc hồ sơ' },
  { id: 5, name: 'Interview', name_vn: 'Phỏng vấn' },
  { id: 6, name: 'Offer', name_vn: 'Đề nghị làm việc' },
  { id: 7, name: 'Hired', name_vn: 'Đã tuyển' }
]

export const TASK_TYPE = [
  {
    id: 0,
    name: 'To-do',
    name_vn: 'To-do'
  },
  {
    id: 1,
    name: 'Call',
    name_vn: 'Gọi'
  },
  {
    id: 2,
    name: 'Email',
    name_vn: 'Gửi mail'
  },
  {
    id: 3,
    name: 'Evalution',
    name_vn: 'Đánh giá'
  }
]

export const TASK_REMINDER = [
  {
    id: 0,
    name: 'No reminder',
    name_vn: 'Không nhắc nhở'
  },
  {
    id: 0.25,
    name: '15 mins',
    name_vn: '15 phút'
  },
  {
    id: 0.5,
    name: '30 mins',
    name_vn: '30 phút'
  },
  {
    id: 1,
    name: '1 hour',
    name_vn: '1 giờ'
  },
  {
    id: 2,
    name: '2 hours',
    name_vn: '2 giờ'
  }
]

export const ENABLE_ADS_FILLTER = [
  {
    id: 0,
    name: 'All'
  },
  {
    id: 1,
    name: 'Enable Ads'
  },
  {
    id: 2,
    name: 'Block Ads'
  }
]

export const TASK_STATUS = [
  {
    id: 0,
    name: 'Open'
  },
  {
    id: 1,
    name: 'Completed'
  }
]

export const TIME_EXPIRED_SHARE_CV = [
  { name: '30 days', name_vn: '30 ngày', value: 30 },
  { name: '45 days', name_vn: '45 ngày', value: 45 },
  { name: '60 days', name_vn: '60 ngày', value: 60 }
]

export const LIST_LOGO_PARTNER = [
  { id: 1, url: '/img/LP/partner_1.png', slug: null, company_id: null },
  {
    id: 2,
    url: '/img/LP/partner_2.png',
    slug: 'cong-ty-co-phan-vien-thong-fpt',
    company_id: 7743
  },
  { id: 3, url: '/img/LP/partner_3.png', slug: null, company_id: null },
  { id: 4, url: '/img/LP/partner_4.png', slug: null, company_id: null },
  {
    id: 5,
    url: '/img/LP/partner_5.png',
    slug: 'ntt-data-vietnam-co-ltd',
    company_id: 9325
  },
  { id: 7, url: '/img/LP/partner_7.png', slug: null, company_id: null },
  {
    id: 8,
    url: '/img/LP/partner_8.png',
    slug: 'cong-ty-bhnt-dai-ichi-viet-nam',
    company_id: 12202
  },
  { id: 9, url: '/img/LP/partner_9.png', slug: null, company_id: null },
  { id: 10, url: '/img/LP/partner_10.png', slug: null, company_id: null },
  {
    id: 11,
    url: '/img/LP/partner_11.png',
    slug: 'cong-ty-co-phan-cong-nghe-haravan',
    company_id: 7637
  },
  { id: 12, url: '/img/LP/partner_12.png', slug: null, company_id: null },
  { id: 13, url: '/img/LP/partner_13.png', slug: null, company_id: null },
  {
    id: 14,
    url: '/img/LP/partner_14.png',
    slug: 'cong-ty-chubb-life-viet-nam',
    company_id: 9738
  }
]

export const LIST_FEEDBACK = [
  {
    id: 1,
    content:
      'We ask recruiting service for engineers and designers to freeC. Not only they recommend us very quick due to platform and AI, but also their consultants support our HR to connect candidates. And freeC create the best path for both companies and candidate.',
    name: 'Mr. Yoshimasa Fukuda',
    position: 'CEO at ORO'
  },
  {
    id: 2,
    content: 'Quick response to job orders. We can receive high quality candidates and CVs in average 3 days with detailed information.',
    name: 'Mr. Ken Vo',
    position: 'CEO Vietnam at LastMile'
  },
  {
    id: 3,
    content:
      'freeC is totally new and advanced version of Recruiting Business. \nTheir unique model using big platform, AI, and consultants will be standard in future recruiting. \nAs a tech company, we realize we need to utilize advanced tool for recruiting.',
    name: 'Mr. Shunsuke Fujita',
    position: 'CEO at Lifull'
  },
  {
    id: 4,
    content: "FreeC's consultant supported our company to find the best fit for our jobs very fast and get closer to candidates.",
    name: 'Ms. Vo Kim Ngan',
    position: 'HR & Administration at KDDI'
  }
]
export const LIST_NEWS = [
  {
    id: 1,
    url: 'https://www.brandsvietnam.com/20316-CEO-freeC-Co-hoi-nao-cho-cac-nganh-tuyen-dung-hau-COVID19',
    image: '/img/LP/news_img_1.png',
    logo: '/img/LP/news_logo_1.png',
    title: 'CEO freeC: Cơ hội nào cho các ngành tuyển dụng hậu COVID?',
    content:
      'CEO Kazuki Kunimoto nhận định nhu cầu tuyển dụng nhiều ngành nghề sẽ đảo chiều hậu COVID-19: ngành đang điêu đứng sẽ hồi phục mạnh mẽ trong khi ngành đang “ăn nên làm ra” sẽ cắt giảm nhân sự.'
  },
  {
    id: 2,
    url:
      'https://dantri.com.vn/lao-dong-viec-lam/diem-danh-5-website-tuyen-dung-viec-lam-uy-tin-hieu-qua-hang-dau-nam-2020-20200207110911233.htm',
    image: '/img/LP/news_img_2.png',
    logo: '/img/LP/news_logo_2.png',
    title: 'Điểm danh 5 website tuyển dụng việc làm uy tín hiệu quả hàng đầu năm 2020',
    content:
      'Vietnamwork sở hữu mạng lưới đối tác lớn, HRChannel chuyên tuyển dụng nhân sự cấp cao, TopCV có lợi thế về nhân lực trẻ, còn freeC khác biệt với các tính năng công nghệ mới mẻ.'
  },
  {
    id: 3,
    url: 'https://startup.vnexpress.net/tin-tuc/hanh-trinh-khoi-nghiep/tham-vong-cua-freec-khi-hop-tac-persol-asia-pacific-4103743.html',
    image: '/img/LP/news_img_3.png',
    logo: '/img/LP/news_logo_3.png',
    title: 'Tham vọng của freeC khi hợp tác PERSOL Asia Pacific',
    content: 'Khoản đầu tư 900.000 USD sẽ được freeC tăng cường sức mạnh nền tảng (platform), trí tuệ nhân tạo và chuyên gia headhunter.'
  },
  {
    id: 4,
    url: 'https://baodautu.vn/freec-ra-mat-chuyen-trang-moi-danh-rieng-cho-nha-tuyen-dung-d124280.html',
    image: '/img/LP/news_img_4.png',
    logo: '/img/LP/news_logo_4.png',
    title: 'FreeC ra mắt chuyên trang mới dành riêng cho nhà tuyển dụng',
    content:
      'Ngoài gói đăng tin tuyển dụng và quảng bá thương hiệu miễn phí cho doanh nghiệp, freeC còn mang đến dịch vụ “săn đầu người” với chi phí tiết kiệm đến 30% cho các vị trí cấp cao.'
  },
  {
    id: 5,
    url: 'https://www.brandsvietnam.com/20398-Startup-cong-nghe-tuyen-dung-freeC-goi-von-thanh-cong-900000USD-tu-Persol',
    image: '/img/LP/news_img_5.png',
    logo: '/img/LP/news_logo_1.png',
    title: 'Startup công nghệ tuyển dụng freeC gọi vốn thành công 900.000USD từ Persol',
    content:
      'Cú bắt tay chiến lược kỳ vọng giúp freeC rút ngắn thời gian tuyển dụng bằng AI và tăng độ chính xác khi gợi ý ứng viên lên gấp 3 lần, cũng như mở rộng thị trường hoạt động sang 13 quốc gia trong khu vực.'
  },
  {
    id: 6,
    url: 'https://theleader.vn/nang-cap-giao-dien-moi-freec-nhanh-chong-chiem-cam-tinh-hang-nghin-nha-tuyen-dung-1609925865318.htm',
    image: '/img/LP/news_img_6.png',
    logo: '/img/LP/news_logo_6.png',
    title: `Nâng cấp giao diện mới – FreeC nhanh chóng 'chiếm cảm tình' hàng nghìn nhà tuyển dụng`,
    content:
      'Giao diện freeC không chỉ cải tiến thiết kế dễ sử dụng hơn, mà còn cho phép nhà tuyển dụng đăng tin miễn phí không giới hạn, chia sẻ quyền tuyển dụng cho cả đội nhóm công ty.'
  }
]
export const ACTIVE_TAB = 'ACTIVE'
export const INVITATION_TAB = 'INVATATION'
export const DEACTIVE_TAB = 'DEACTIVE'
export const COMPANY_SIZES = [
  { id: 0, value: '1-50 employees', value_vn: '1-50 nhân viên' },
  { id: 1, value: '51-100 employees', value_vn: '51-100 nhân viên' },
  { id: 2, value: '101-300 employees', value_vn: '101-300 nhân viên' },
  { id: 3, value: '300+ employees', value_vn: '300+ nhân viên' }
]
export const TAB_DESKTOP = 'DESKTOP'
export const TAB_MOBILE = 'MOBILE'
export const LIST_SALARY_TYPE = [
  { id: 0, name: 'Range', name_vn: 'Khoảng lương' },
  { id: 1, name: 'Negotiable', name_vn: 'Thoả thuận' }
]
export const ROLE_PROFILE = {
  0: 'recruiter_admin',
  1: 'recruiter_member',
  2: 'consultant_admin',
  3: 'consultant_member',
  4: 'admin_super_admin',
  5: 'admin_member'
}
