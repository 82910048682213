import { useEffect, useState } from 'react'
import { getFirebaseMessageFCMToken, isFirebaseSuppored, registerFirebaseMessage } from '../firebase'
import { saveUserDevicesToken } from '../api/notification.api'
import { MessagePayload } from 'firebase/messaging'
import { useCurrentUser } from 'src/@legacy/@core/hooks/CurrentUserHooks'
import { createSimpleEmitter } from '../../../@legacy/@core/utilities/Emitter'
import { createUseLocalStorageStage } from 'src/@legacy/@optimal-page/hooks/share/StorageHooks'
import { createSingleTimeInvoke } from '../../../@legacy/@core/utilities/SingleInvokeFuction'
import { getIsServer } from '../../../@legacy/utilities/common'

export const [useNotificationModalState] = createUseLocalStorageStage<boolean>('notification', () => Promise.resolve(false))

const saveToken = (token) => saveUserDevicesToken(token)
const safelySaveToken = createSingleTimeInvoke(saveToken)

export const useRegisterNoticationWhenLoginNotification = () => {
  const [currentUser] = useCurrentUser()
  const [isFireBaseSupport, setIsFirebaseSupport] = useState(false)
  useEffect(() => {
    isFirebaseSuppored().then((supported) => setIsFirebaseSupport(supported))
  }, [])

  useEffect(() => {
    if (currentUser && isFireBaseSupport && Notification.permission === 'granted') {
      getFirebaseMessageFCMToken()
        .then((token) => {
          return safelySaveToken(token)
        })
        .catch(() => {})
    }
  }, [currentUser, isFireBaseSupport])
}

const { emitAll, regiterCallback, unregisterCallBack } = createSimpleEmitter<MessagePayload>()
const dispatcher = (payload) => {
  emitAll(payload)
}
if (!getIsServer() && navigator.serviceWorker) {
  navigator.serviceWorker.onmessage = (event) => {
    if (event.data && event.data.type === 'BACKGROUND_MESSAGE_RECEIVE') {
      emitAll(event.data.count)
    }
  }
}

export const useNotificationReceiver = (handle: (payload: MessagePayload) => Promise<void>) => {
  useEffect(() => {
    registerFirebaseMessage(dispatcher)
    regiterCallback(handle)
    return () => unregisterCallBack(handle)
  }, [handle])
}
