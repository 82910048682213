// eslint-disable-next-line no-restricted-imports
import { NextRouter, useRouter } from 'next/router'
import { match } from 'path-to-regexp'
import { useMemo } from 'react'
import { useGetAppRealPath, useServerService } from '../components/FreecProvider'
import { getPathSpecificLanguage, translateRouterHref } from '../components/share/AppLink'
import { DEFAULT_LOCALE } from '../utilities/Constant'
import { COOKIES_NAME_LANGUAGE, getCookie } from '../utilities/Cookies'
import { getIsServer } from '../utilities/SSR'
const optimalPage = ['/jobs/:path', '/jobs/:path/apply-cv-online', '/company/:path', '/companies', '/jobs']
const isMatchOptimalPage = (url) => !!optimalPage.find((pattern) => match(pattern)(url.split('?')[0]))
export const useAppRouter = (): NextRouter => {
  const router = useRouter()
  const getAppRealPath = useGetAppRealPath()
  const serverService = useServerService()
  const realPath = getAppRealPath()
  const locale =
    getPathSpecificLanguage(realPath, router?.locale) ||
    (getIsServer()
      ? serverService
        ? serverService.getCookie(COOKIES_NAME_LANGUAGE)
        : router?.locale
      : getCookie(COOKIES_NAME_LANGUAGE)) ||
    DEFAULT_LOCALE
  const push: typeof router.push = (url, as, options) => {
    const translatedLink = translateRouterHref(url as string, options?.locale || locale)
    if (isMatchOptimalPage(url)) {
      window.location.href = as?.toString() || translatedLink
      return
    }
    return router.push(translatedLink, as || translatedLink, { ...options, locale: false })
  }
  return useMemo(
    () => ({
      ...router,
      push
    }),
    [router]
  )
}
