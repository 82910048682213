export default {
  data: [
    {
      id: '1',
      type: 'job_categories',
      attributes: {
        id: 1,
        name: 'HR \u0026 Recruitment',
        icon: 'team',
        name_vn: 'Nhân sự \u0026 Tuyển dụng',
        subs: {
          data: [
            { id: '2', type: 'sub_category', attributes: { id: 2, name: 'C\u0026B', name_vn: 'C\u0026B' } },
            {
              id: '3',
              type: 'sub_category',
              attributes: { id: 3, name: 'Training \u0026 Development', name_vn: 'Đào Tạo \u0026 Phát Triển' }
            },
            { id: '4', type: 'sub_category', attributes: { id: 4, name: 'Recruitment', name_vn: 'Tuyển Dụng' } },
            { id: '5', type: 'sub_category', attributes: { id: 5, name: 'HRBP', name_vn: 'HRBP' } }
          ]
        }
      }
    },
    {
      id: '6',
      type: 'job_categories',
      attributes: {
        id: 6,
        name: 'IT/Software',
        icon: 'laptop',
        name_vn: 'IT/ Phần mềm',
        subs: {
          data: [
            { id: '7', type: 'sub_category', attributes: { id: 7, name: 'AI/ML Developer', name_vn: 'AI/ML Developer' } },
            { id: '8', type: 'sub_category', attributes: { id: 8, name: 'Bridge Engineer', name_vn: 'Bridge Engineer' } },
            { id: '9', type: 'sub_category', attributes: { id: 9, name: 'Backend Developer', name_vn: 'Backend Developer' } },
            { id: '10', type: 'sub_category', attributes: { id: 10, name: 'Data Scientist', name_vn: 'Data Scientist' } },
            { id: '11', type: 'sub_category', attributes: { id: 11, name: 'Network', name_vn: 'Network' } },
            { id: '12', type: 'sub_category', attributes: { id: 12, name: 'DevOps', name_vn: 'DevOps' } },
            { id: '13', type: 'sub_category', attributes: { id: 13, name: 'IT Helpdesk', name_vn: 'IT Helpdesk' } },
            { id: '14', type: 'sub_category', attributes: { id: 14, name: 'Mobile Developer', name_vn: 'Mobile Developer' } },
            { id: '15', type: 'sub_category', attributes: { id: 15, name: 'QA \u0026 QC', name_vn: 'QA \u0026 QC' } },
            { id: '16', type: 'sub_category', attributes: { id: 16, name: 'Frontend Developer', name_vn: 'Frontend Developer' } },
            { id: '17', type: 'sub_category', attributes: { id: 17, name: 'Data Analyst', name_vn: 'Data Analyst' } },
            { id: '18', type: 'sub_category', attributes: { id: 18, name: 'Business Analyst', name_vn: 'Business Analyst' } },
            { id: '19', type: 'sub_category', attributes: { id: 19, name: 'Fullstack Developer', name_vn: 'Fullstack Developer' } },
            { id: '20', type: 'sub_category', attributes: { id: 20, name: 'Blockchain Developer', name_vn: 'Blockchain Developer' } },
            { id: '24', type: 'sub_category', attributes: { id: 24, name: 'Scrum Master', name_vn: 'Scrum Master' } },
            { id: '25', type: 'sub_category', attributes: { id: 25, name: 'Product Owner', name_vn: 'Product Owner' } },
            {
              id: '27',
              type: 'sub_category',
              attributes: { id: 27, name: 'Technical Product Manager', name_vn: 'Technical Product Manager' }
            },
            { id: '78', type: 'sub_category', attributes: { id: 78, name: 'IT Project manager', name_vn: 'IT Project manager' } }
          ]
        }
      }
    },
    {
      id: '21',
      type: 'job_categories',
      attributes: {
        id: 21,
        name: 'Management',
        icon: 'setting',
        name_vn: 'Quản lý',
        subs: {
          data: [
            { id: '22', type: 'sub_category', attributes: { id: 22, name: 'Project manager', name_vn: 'Project manager' } },
            { id: '23', type: 'sub_category', attributes: { id: 23, name: 'Product manager', name_vn: 'Product manager' } },
            { id: '26', type: 'sub_category', attributes: { id: 26, name: 'Engineering Manager', name_vn: 'Engineering Manager' } }
          ]
        }
      }
    },
    {
      id: '28',
      type: 'job_categories',
      attributes: {
        id: 28,
        name: 'Operations/CS/Adminstrative',
        icon: 'user',
        name_vn: 'Hành chính/Chăm sóc khách hàng',
        subs: {
          data: [
            { id: '29', type: 'sub_category', attributes: { id: 29, name: 'Interpreter/Translator', name_vn: 'Biên/Phiên Dịch' } },
            { id: '30', type: 'sub_category', attributes: { id: 30, name: 'General Admin', name_vn: 'Tổng Vụ' } },
            { id: '31', type: 'sub_category', attributes: { id: 31, name: 'Law/Legal', name_vn: 'Pháp Lý' } },
            { id: '32', type: 'sub_category', attributes: { id: 32, name: 'Secretary/Assistant', name_vn: 'Trợ Lý/Thư Ký' } },
            { id: '33', type: 'sub_category', attributes: { id: 33, name: 'Customer Support', name_vn: 'Chăm Sóc Khách Hàng' } },
            { id: '34', type: 'sub_category', attributes: { id: 34, name: 'Office Management', name_vn: 'Quản lý văn phòng' } },
            { id: '35', type: 'sub_category', attributes: { id: 35, name: 'Operation Management', name_vn: 'Quản lý vận hành' } }
          ]
        }
      }
    },
    {
      id: '36',
      type: 'job_categories',
      attributes: {
        id: 36,
        name: 'Accounting \u0026 Finance',
        icon: 'calculator',
        name_vn: 'Kế Toán \u0026 Tài chính',
        subs: {
          data: [
            { id: '37', type: 'sub_category', attributes: { id: 37, name: 'Accounting', name_vn: 'Kế Toán' } },
            { id: '38', type: 'sub_category', attributes: { id: 38, name: 'Banking', name_vn: 'Ngân Hàng' } },
            { id: '39', type: 'sub_category', attributes: { id: 39, name: 'Finance', name_vn: 'Tài Chính Doanh Nghiệp' } },
            { id: '40', type: 'sub_category', attributes: { id: 40, name: 'Insurance', name_vn: 'Bảo Hiểm' } },
            { id: '41', type: 'sub_category', attributes: { id: 41, name: 'Risk management', name_vn: 'Quản Lý Rủi Ro' } },
            { id: '42', type: 'sub_category', attributes: { id: 42, name: 'Data Analyst', name_vn: 'Data Analyst' } },
            { id: '43', type: 'sub_category', attributes: { id: 43, name: 'Investment', name_vn: 'Đầu Tư' } },
            { id: '44', type: 'sub_category', attributes: { id: 44, name: 'Auditing', name_vn: 'Kiểm toán' } },
            { id: '45', type: 'sub_category', attributes: { id: 45, name: 'Tax', name_vn: 'Thuế' } }
          ]
        }
      }
    },
    {
      id: '46',
      type: 'job_categories',
      attributes: {
        id: 46,
        name: 'Marketing \u0026 PR',
        icon: 'sound',
        name_vn: 'Marketing \u0026 PR',
        subs: {
          data: [
            { id: '47', type: 'sub_category', attributes: { id: 47, name: 'Brand Management', name_vn: 'Brand Management' } },
            {
              id: '48',
              type: 'sub_category',
              attributes: { id: 48, name: 'Communication \u0026 Promotion', name_vn: 'Quan Hệ Công Chúng' }
            },
            { id: '49', type: 'sub_category', attributes: { id: 49, name: 'Digital Marketing', name_vn: 'Digital Marketing' } },
            { id: '50', type: 'sub_category', attributes: { id: 50, name: 'Content Marketing', name_vn: 'Content Marketing' } },
            { id: '51', type: 'sub_category', attributes: { id: 51, name: 'Event', name_vn: 'Event' } },
            { id: '52', type: 'sub_category', attributes: { id: 52, name: 'Market Research', name_vn: 'Market Research' } },
            { id: '53', type: 'sub_category', attributes: { id: 53, name: 'Trade Marketing', name_vn: 'Trade Marketing' } }
          ]
        }
      }
    },
    {
      id: '54',
      type: 'job_categories',
      attributes: {
        id: 54,
        name: 'Design/Media',
        icon: 'bulb',
        name_vn: 'Thiết kế/Media',
        subs: {
          data: [
            {
              id: '55',
              type: 'sub_category',
              attributes: { id: 55, name: 'Film \u0026 Video Editor', name_vn: 'Biên Tập Phim \u0026 Video' }
            },
            { id: '56', type: 'sub_category', attributes: { id: 56, name: 'UI/UX', name_vn: 'UI/UX' } },
            { id: '57', type: 'sub_category', attributes: { id: 57, name: 'Interior Design', name_vn: 'Thiết Kế Nội Thất' } },
            { id: '58', type: 'sub_category', attributes: { id: 58, name: 'Photographer', name_vn: 'Chụp Ảnh' } },
            { id: '59', type: 'sub_category', attributes: { id: 59, name: 'Multimedia Artist', name_vn: 'Đa Phương Tiện' } },
            { id: '60', type: 'sub_category', attributes: { id: 60, name: 'Graphic Design', name_vn: 'Thiết Kế Đồ Hoạ' } }
          ]
        }
      }
    },
    {
      id: '61',
      type: 'job_categories',
      attributes: {
        id: 61,
        name: 'Sales \u0026 Business Development',
        icon: 'line-chart',
        name_vn: 'Kinh doanh \u0026 Bán hàng',
        subs: {
          data: [
            { id: '62', type: 'sub_category', attributes: { id: 62, name: 'B2B Sales', name_vn: 'B2B Sales' } },
            { id: '63', type: 'sub_category', attributes: { id: 63, name: 'B2C Sales', name_vn: 'B2C Sales' } },
            { id: '64', type: 'sub_category', attributes: { id: 64, name: 'Ecommerce', name_vn: 'Thương Mại Điện Tử' } },
            { id: '65', type: 'sub_category', attributes: { id: 65, name: 'Sale Admin', name_vn: 'Sales Admin' } },
            { id: '66', type: 'sub_category', attributes: { id: 66, name: 'Telesales', name_vn: 'Telesales' } },
            { id: '67', type: 'sub_category', attributes: { id: 67, name: 'Business Development', name_vn: 'Business Development' } }
          ]
        }
      }
    },
    {
      id: '68',
      type: 'job_categories',
      attributes: {
        id: 68,
        name: 'Others',
        icon: 'ellipsis',
        name_vn: 'Khác',
        subs: {
          data: [
            { id: '69', type: 'sub_category', attributes: { id: 69, name: 'Education', name_vn: 'Giáo Dục' } },
            { id: '70', type: 'sub_category', attributes: { id: 70, name: 'Export/Import', name_vn: 'Xuất/Nhập Khẩu' } },
            { id: '71', type: 'sub_category', attributes: { id: 71, name: 'Engineer', name_vn: 'Kỹ Sư' } },
            { id: '72', type: 'sub_category', attributes: { id: 72, name: 'Logistic', name_vn: 'Logistic' } },
            { id: '73', type: 'sub_category', attributes: { id: 73, name: 'Hospitality', name_vn: 'Nhà Hàng Khách Sạn' } },
            { id: '74', type: 'sub_category', attributes: { id: 74, name: 'Manufacturing', name_vn: 'Sản Xuất' } },
            { id: '75', type: 'sub_category', attributes: { id: 75, name: 'Medical', name_vn: 'Y Khoa' } },
            { id: '76', type: 'sub_category', attributes: { id: 76, name: 'Architect', name_vn: 'Kiến trúc' } },
            { id: '77', type: 'sub_category', attributes: { id: 77, name: 'Sciences', name_vn: 'Khoa Học' } }
          ]
        }
      }
    }
  ]
}
