export const createSingleTimeInvoke = <F extends Function>(func: F) => {
  let invoked = false
  return function (...args) {
    if (invoked === false) {
      //@ts-ignore
      func(...args)
      invoked = true
    }
  }
}
export const createOneTimeRun = () => {
  let invoked = false
  return (func: () => void) => {
    if (invoked === false) {
      func()
      invoked = true
    }
  }
}
